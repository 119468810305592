import React from "react";
import PropTypes from "prop-types";

function Figure({src, alt, children, className}) {
  return (
    <div className={className}>
      <img src={src} alt={alt}/>
      <div className="text-center mt-2 md:px-10">{ children }</div>
    </div>
  )
}

Figure.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Figure;