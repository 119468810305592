import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Figure from "../components/figure"

import TextSection from "../components/text-section"

import timascanConcept from "../images/goals/timascan-concept.png"
import workflow from "../images/goals/timascan-research-line-workflow.png"


import ercLogo from "../images/erc-logo.svg"
import euroflowLogo from "../images/euroflow-logo.svg"
import lumcLogo from "../images/lumc-logo.svg"

function GoalsPage() {
  return (
    <Layout>
      <SEO
        keywords={[`timascan`, `ERC`, `cancer`, `macrophages`]}
        title="Goals"
      />
      <section>
        <h1 className="text-4xl mb-4">Goals</h1>
        <TextSection title="Monitoring of cancer via tissue macrophages">
          The increasing knowledge, obtained in the past decades, on the biology and function of monocytes, macrophages and dendritic cells, 
          revealed key functions for these cells in maintenance of homeostatic balance that go beyond antigen presentation. 
          Their ability to monitor and sense micro-environmental changes, and perform tissue clean-up tasks, make these cells ideal candidates for assessment of disturbances in homeostasis. 
          In line with this, several studies have shown the relevance of monitoring myeloid antigen presenting cells in patient care settings. 
          Nevertheless, despite the extensive research on the monocyte-macrophage system, 
          the usefulness of evaluating these populations is still limited due to lack of knowledge of the precise nature of the monocyte-macrophage maturation pathway and their relation with dendritic cell populations across the different tissues.
        </TextSection>

        <TextSection>
          The TiMaScan project aims at truly translational research by focusing on understanding the processes involved in the differentiation of the monocytic lineage and their relation with dendritic cells, 
          as a basis for development of new, standardized tools to be implemented in patient care. In order to do so, we employ multi-omics approaches, 
          including transcriptomics, proteomics and high-end flow and mass cytometry, for phenotypic and functional evaluation of the monocyte-macrophage system across different body compartments, 
          in both steady-state and in response to distinct “stressors” such as cancer, infection, vaccination or trauma (Figure 1).
        </TextSection>

        <Figure alt="Picture of timascan concept" src={workflow} className="my-8"><b>Figure 1: </b>TiMaScan research line workflow.</Figure>

        <TextSection>
          More specifically, we apply the knowledge obtained from the dissection of the monocyte-macrophage system maturation pathway 
          (particularly regarding the recirculation of macrophages from tissues via the lymph system to the blood stream), 
          phagocytosis and digestion processes, for the design of an innovative, minimally-invasive diagnostic approach for early detection of cancer cells. 
          This is based on the novel concept (Figure 2) that tissue macrophages (TiMas) continuously phagocytize and digest apoptotic cells (particularly in case of cancer) and subsequently recirculate to the blood stream, 
          where the TiMas can be detected and scanned for their phagolysosomal contents (TiMaScan), using the novel EuroFlow-based advanced flow cytometric techniques.
        </TextSection>

        <Figure alt="Picture of timascan concept" src={timascanConcept} className="my-8 md:px-20"><b>Figure 2: </b>TiMaScan concept.</Figure>

        <div className="md:flex justify-between my-10">
          <div className="md:flex-none flex justify-center">
            <img
                alt="ERC logo"
                className="w-auto h-20"
                src={ercLogo}
              />
          </div>
          <div className="md:flex-none flex justify-center md:m-0 mt-10">
            <img
              alt="LUMC logo"
              className="h-20 w-auto"
              src={lumcLogo}
            />
          </div>
          <div className="md:flex-none flex justify-center md:m-0 mt-10">
            <img
              alt="EuroFlow logo"
              className="h-20"
              src={euroflowLogo}
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default GoalsPage